<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card title="User Profile" class="mb-base" style="min-height: 100%">
        <div class="vx-row mr-4">
          <div class="vx-col w-2/3" style="display: flex">
            <div class="w-1/1 w-full ml-3">
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Name</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.name }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>ID Number</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.id_number }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Work ID Number</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.work_id_number }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>External Code</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.external_code }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Email</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.email }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Mobile</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.mobile }}</span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Address</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.address }}</span>
                </div>
              </div>
              <!-- <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Territory Area</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <span>{{ data.territory_area }}</span>
                </div>
              </div> -->
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Territories</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <ul>
                    <li
                      v-for="(territory, index) in data.territories"
                      :key="index"
                    >
                      {{ territory.code }} {{ territory.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-2/5 w-full">
                  <span>Roles</span>
                </div>
                <div class="vx-col sm:w-3/5 w-full">
                  <ul>
                    <li v-for="(role, index) in data.roles" :key="index">
                      {{ role.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="vx-col w-1/3">
            <div class="w-1/1 w-full">
              <div class="mb-4">
                <img
                  style="max-height: 130px; max-width: auto"
                  :src="data.avatar"
                  class="rounded"
                />
              </div>
            </div>
          </div>
          <div class="vx-col w-full flex mt-5">
            <!-- <vs-button
              @click="handleEdit()"
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              >Edit</vs-button
            > -->
            <vs-button
              @click="handleChangePassword()"
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              >Change Password</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.getData();
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {},
      };
    },
    handleEdit() {
      this.$router.push("profile/edit");
    },
    handleChangePassword() {
      this.$router.push("profile/change-password");
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/account/v1/profile/me").then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.data = resp.data;
        }
      });
    },
  },
};
</script>
